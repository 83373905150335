// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Tylers Files\\Projects\\Personal\\Code Repos\\scd-gatsby\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("C:\\Tylers Files\\Projects\\Personal\\Code Repos\\scd-gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("C:\\Tylers Files\\Projects\\Personal\\Code Repos\\scd-gatsby\\src\\pages\\about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-claims-audits-jsx": () => import("C:\\Tylers Files\\Projects\\Personal\\Code Repos\\scd-gatsby\\src\\pages\\claims-audits.jsx" /* webpackChunkName: "component---src-pages-claims-audits-jsx" */),
  "component---src-pages-index-jsx": () => import("C:\\Tylers Files\\Projects\\Personal\\Code Repos\\scd-gatsby\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-publications-jsx": () => import("C:\\Tylers Files\\Projects\\Personal\\Code Repos\\scd-gatsby\\src\\pages\\publications.jsx" /* webpackChunkName: "component---src-pages-publications-jsx" */),
  "component---src-pages-services-jsx": () => import("C:\\Tylers Files\\Projects\\Personal\\Code Repos\\scd-gatsby\\src\\pages\\services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

exports.data = () => import("C:\\Tylers Files\\Projects\\Personal\\Code Repos\\scd-gatsby\\.cache\\data.json")

