module.exports = [{
      plugin: require('C:/Tylers Files/Projects/Personal/Code Repos/scd-gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-115149439-1"},
    },{
      plugin: require('C:/Tylers Files/Projects/Personal/Code Repos/scd-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Tylers Files/Projects/Personal/Code Repos/scd-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
